import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IBaseDto } from '../dto/IBaseDto';
import { ICustomerDto } from '../dto/ICustomerDto';
import { IProductDto } from "../dto/IProductDto";
import { ISourceSystemDto } from "../dto/ISourceSystemDto";
import { ILobDto } from "../dto/ILobDto";
import { IRecepiDto } from "../dto/IRecepiDto";
import { IDatasetDto } from '../dto/DatasetDto';
import { IDatasetAttribute } from '../dto/IDatasetAttribute';
import { IStepDto } from '../dto/IStepDto';
import { HttpBaseService } from './http-services/http-base';
import { IStepMergeDto } from '../dto/IStepMerge';
import { ICommentDto } from '../dto/ICommentDto';
import { IDuplicateRecipe, IRecipeIdentification } from '../feature-recipe/recipe-item/dto';
import { IStepOutputDto } from '../dto/IStepOutputDto';
import { IJobDto } from '../dto/IJobDto';


@Injectable()
export class DataDropService {

  public saveColumnOutputList(stepId: number, columnOutputListRequest: { attributeId: number; dropped: boolean; }[]) {
    const dto = { attributes: columnOutputListRequest };
    return this.httpService.patch<void>(`steps/${stepId}/outputs`, dto);
  }

  public async getPreviousStepId(recipeId: number, currentStepId: number): Promise<number> {
    const stepListDto: IStepDto[] = (await this.getStepListByRecipeId(recipeId).toPromise()).data;
    const firstStep = stepListDto.length > 0 ? stepListDto[0].stepId : null;

    if (currentStepId == 0 && stepListDto.length > 0)
      return stepListDto.slice(-1)[0].stepId;

    if (firstStep == null || firstStep == currentStepId)
      return null;

    const currentStep = stepListDto.find(x => x.stepId == currentStepId);
    const currentStepIndex = stepListDto.indexOf(currentStep);
    if (currentStepIndex > 0)
      return stepListDto[currentStepIndex - 1].stepId;

    return null;

  }

  public createComment(stepId: number, stepComment: string): Observable<ICommentDto> {
    const dto = { stepComment: stepComment } as ICommentDto;
    return this.httpService.create<ICommentDto>(`steps/${stepId}/comments`, dto);
  }


  public getStepCommnets(stepId: number): Observable<IBaseDto<Array<ICommentDto>>> {
    return this.httpService.get<IBaseDto<Array<ICommentDto>>>(`steps/${stepId}/comments`);
  }
  deleteRecipe(recipeId: any) {
    return this.httpService.delete<any>(`recipes/${recipeId}`);
  }

  removeStepById(stepId: number): Observable<any> {
    return this.httpService.delete<any>(`steps/${stepId}`);
  }

  getStepListByRecipeId(recipeId): Observable<IBaseDto<Array<IStepDto>>> {
    return this.httpService.get<IBaseDto<Array<IStepDto>>>(`Recipes/${recipeId}/steps`);
  }
  getStepOutputByStepId(stepId: number): Observable<IBaseDto<Array<IStepOutputDto>>> {
    return this.httpService.get<IBaseDto<Array<IStepOutputDto>>>(`steps/${stepId}/outputs`);
  }

  saveStep<T>(step: IStepMergeDto) {
    if (step.stepId == 0) {
      return this.httpService.create<T>(`MergeSteps`, step);
    } else {
      return this.httpService.update<T>(`MergeSteps/${step.stepId}`, step);
    }
  }

  getMergeStepById(stepId: number): Observable<IStepMergeDto> {
    return this.httpService.get<IStepMergeDto>(`MergeSteps/${stepId}`);
  }

  saveRecepi(recipeDto: IRecepiDto): Observable<IRecepiDto> {
    const isNewRecord = recipeDto.recipeId == 0;
    if (isNewRecord) {
      return this.httpService.create<IRecepiDto>(`Recipes`, recipeDto);
    }
    else {
      return this.httpService.update<IRecepiDto>(`Recipes/${recipeDto.recipeId}`, recipeDto);
    }
  }
  getRecipeById(recipeId: string): Observable<IBaseDto<IRecepiDto>> {
    return this.httpService.get<IBaseDto<IRecepiDto>>(`Recipes/${recipeId}`);
  }
  getDatasetAttribute(datasetId: number): Observable<IBaseDto<Array<IDatasetAttribute>>> {
    if (datasetId == null)
      return of(null);
    return this.httpService.get<IBaseDto<Array<IDatasetAttribute>>>(`Datasets/${datasetId}/attributes/`);
  }

  getCustomerDatasetsInRecipe(recipeId: string): Observable<IBaseDto<Array<string>>> {
    return this.httpService.get<IBaseDto<Array<string>>>(`Recipes/${recipeId}/datasets`);
  }

  constructor(private http: HttpClient, private httpService: HttpBaseService) {  }

  getCustomer(): Observable<IBaseDto<Array<ICustomerDto>>> {
    return this.httpService.get<IBaseDto<Array<ICustomerDto>>>("Customer");
  }

  getSourceSystem(customerId: number): Observable<IBaseDto<Array<ISourceSystemDto>>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", customerId.toString());
    return this.httpService.get<IBaseDto<Array<ISourceSystemDto>>>("SourceSystem", queryParams);
  }

  getProduct(customerId: number, sourceSystemId: number): Observable<IBaseDto<Array<IProductDto>>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", customerId.toString());
    queryParams = queryParams.append("sourceSystemId", sourceSystemId.toString());
    return this.httpService.get<IBaseDto<Array<IProductDto>>>("Products", queryParams);
  }

  createProduct(productName: string): Observable<IProductDto> {
    let req = {productName: productName};
    return this.httpService.create<IProductDto>("Products", req);
  }

  getLOB(customerId: number, sourceSystemId: number, productId: number): Observable<IBaseDto<Array<ILobDto>>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", customerId.toString());
    queryParams = queryParams.append("sourceSystemId", sourceSystemId.toString());
    queryParams = queryParams.append("productId", productId.toString());
    return this.httpService.get<IBaseDto<Array<ILobDto>>>("Lobs", queryParams);
  }

  createLOB(lobName: string, productId: number, lobCode: string): Observable<ILobDto> {
    let req = {lobName, productId, lobCode};
    return this.httpService.create<ILobDto>("Lobs", req);
  }

  DatasetByRecipeIdentification(ri: IRecipeIdentification, cache: boolean = true): Observable<IBaseDto<Array<IDatasetDto>>> {
    return this.DatasetByCustomerSourceProdactLoi(ri.customerId, ri.sourceSystemId, ri.productId, ri.lobId);
  }

  badRequestWrongUrl() {
    return this.httpService.get<IBaseDto<Array<IDatasetDto>>>("badRequestWrongUrl");
  }

  DatasetByCustomerSourceProdactLoi(customerId: number, sourceSystemId: number, productId: number, lobId: number,
    cache: boolean = true): Observable<IBaseDto<Array<IDatasetDto>>> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("customerId", customerId.toString());
    queryParams = queryParams.append("sourceSystemId", sourceSystemId.toString());
    queryParams = queryParams.append("productId", productId.toString());
    queryParams = queryParams.append("lobId", lobId.toString());
    return this.httpService.get<IBaseDto<Array<IDatasetDto>>>("Datasets", queryParams);

  }

  getTargetDatasets(productId: number, lobId: number): Observable<IBaseDto<Array<ILobDto>>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("productId", productId.toString());
    queryParams = queryParams.append("lobId", lobId.toString());
    return this.httpService.get<IBaseDto<Array<ILobDto>>>("targetdatasets", queryParams);
  }

  getTargetDatasetById(id: number): Observable<any> {
    return this.httpService.get<any>(`targetdatasets/${id}`);
  }

  saveTargetDataset<T>(dataset: any) {
    if (dataset.id === 0) {
      return this.httpService.create<T>(`targetdatasets`, dataset);
    } else {
      return this.httpService.update(`targetdatasets/${dataset.id}`, dataset);
    }
  }
  
  saveCustomerDataset<T>(dataset: any) {
    if (dataset.id === 0) {
      return this.httpService.create<T>(`datasets`, dataset);
    } else {
      return this.httpService.update(`datasets/${dataset.id}`, dataset);
    }
  }

  getRecipe(datasetId: number): Observable<IBaseDto<Array<IRecepiDto>>> {
    return this.httpService.get<IBaseDto<Array<IRecepiDto>>>(`Datasets/${datasetId}/recipes`);
  }

  runJob(payload: any): Observable<string> {
    return this.httpService.runJob<string>(`Jobs/startjob`, payload);
  }

  getJobFiles(datasetId: number, startDate: string, endDate: string): Observable<IBaseDto<Array<IJobDto>>> {
    return this.httpService.get<IBaseDto<Array<IJobDto>>>(`Jobs/${datasetId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getCustomerById(customerId: number): Observable<ICustomerDto> {
    return this.httpService.get<ICustomerDto>(`Customer/${customerId}`);
  }

  saveCustomer(customer: ICustomerDto): Observable<number> {
    if (customer.id == 0) {
      return this.httpService.create(`Customer`, customer);
    } else {
      return this.httpService.update(`Customer/${customer.id}`, customer);
    }
  }

  createSourceSystems(sourceSystems): Observable<number[]> {
    return this.httpService.create(`sourceSystem`, sourceSystems);
  }

  getDatasetDelimiter(scopeId: number, datasetName: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("scopeId", scopeId.toString());
    queryParams = queryParams.append("datasetName", datasetName);
    return this.httpService.get<any>("Glue/table-delimiter", queryParams);
  }

  getDatasetDetailsById(id: number): Observable<any> {
    return this.httpService.get<any>(`datasets/${id}/details`);
  }

  getScopesByCustomerId(customerId: number): Observable<any> {
    return this.httpService.get<any>(`scopes/${customerId}/all`);
  }

  createScope(scope: any): Observable<any> {
    return this.httpService.create(`scopes`, scope);
  }

  checkDuplicateName(name: string, datasetId: string, recipeId: string): Observable<boolean> {
    let duplicateRecipeReq: IDuplicateRecipe = { recipeId: recipeId, recipeName: name, datasetId: datasetId };
    return this.httpService.create(`Recipes/duplicate`, duplicateRecipeReq);
  }
}

