import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonUiModule } from '@uw-verisk/common-ui';
import { rootRouterConfig } from './app.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/shared/footer/footer.component';
import { S3Component } from './components/shared/s3/s3.component';
import { LeftNavComponent } from './components/shared/left-nav/left-nav.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { BusinessUIModule } from '@uw-verisk/business-ui';
import { userPreferencesReducer } from './store/reducers/user-preferences-reducer';
import { GridModule } from '@uw-verisk/grid';
import { AppService } from './app.service';
import { ErrorInterceptor } from './components/shared/interceptor/error.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as config from '../assets/jsons/config.json';
import { HeaderComponent } from './components/shared/header/header.component';
import { AuthComponent } from './components/shared/auth/auth.component';
import { AgGridModule } from 'ag-grid-angular';
import { DataDropService } from './services/http.datadrop.service';
import { LeftMenuComponent } from './components/shared/left-menu/left-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from './shared/shared.module';
import { StorageService } from './services/storage.service';
import { HttpRecipeStepMap } from './services/http.recipe.step-map.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { VrskAuthOktaModule, VrskAuthOktaAccessTokenInterceptor, VRSK_AUTH_OKTA_CONFIG } from '@uw-verisk/auth-okta';
import { S3Interceptor } from './core/interceptors/S3Interceptor';
import { CalendarModule } from 'primeng/calendar'

const appInitializerFn = (configService: AppService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    FooterComponent,
    HeaderComponent,
    LeftNavComponent,
    S3Component,
    LeftMenuComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    NgbModule,
    // OAuthModule.forRoot({
    //   resourceServer: {
    //     allowedUrls: [''],
    //     sendAccessToken: true,
    //   }
    // }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    StoreModule.forRoot({ userPreferences: userPreferencesReducer }),
    GoogleTagManagerModule.forRoot({
      id: config.analyticsTag
    }),
    ReactiveFormsModule,
    AgGridModule,
    CalendarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    // MatAutocompleteModule,
    SharedModule,
    NgxSpinnerModule,
    CommonUiModule,
    BusinessUIModule,
    GridModule,
    VrskAuthOktaModule    
  ],
  providers: [
    DataDropService,
    HttpRecipeStepMap,
    StorageService,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VrskAuthOktaAccessTokenInterceptor,
      multi: true
    },
    // (Optional) Change config.json path
    {
      provide: VRSK_AUTH_OKTA_CONFIG,
      useValue: 'assets/jsons/config.json'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: S3Interceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule { }

