import { Component } from "@angular/core";
import { ICellRenderer, ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'ag-tree-view',
    template: ''
})

export class TreeCellRendererComponent implements ICellRenderer {
    public params: any;
    public eGui!: HTMLElement;
    public eventListener: any;
    public isLoading = false;
    
    public init(params: any): void {
        this.params = params;
        this.eGui = document.createElement("div");
        this.params.renderer.setStyle(this.eGui, 'padding-left', `${params.data.level}px`);
        this.params.renderer.setStyle(this.eGui, 'cursor', 'pointer');

        if (!params.data.isFile) {
            this.renderFolderFileCell(params);
            this.eventListener = () => { this.isLoading = true; 
                this.eGui.innerHTML = (this.isLoading===true ? `${this.spinnerIcon()}`:'') +
                `${this.folderIcon("")}&nbsp;${this.formatValue(params.value)}`; 
                this.updateData(params.data.customId) };
            this.eGui.addEventListener("click", this.eventListener);
        }
        else {
            this.eGui.innerHTML = "";
        }
        params.columnApi.autoSizeAllColumns();
    }

    private renderFolderFileCell(params: any) {
        if (params.data.expanded) {
            this.isLoading = false;
            if (params.data.isMaterializedDataset) {
                this.eGui.innerHTML = `${this.materializedFolderIcon("-open")}&nbsp;${this.formatValue(params.value)}`;
            } else {
                this.eGui.innerHTML = `${this.folderIcon("-open")}&nbsp;${this.formatValue(params.value)}`;
            }
        } else {
            this.eGui.innerHTML = (this.isLoading === true ? `${this.spinnerIcon()}` : '') +
                (params.data.isMaterializedDataset ? `${this.materializedFolderIcon("")}&nbsp;${this.formatValue(params.value)}` :
                    `${this.folderIcon("")}&nbsp;${this.formatValue(params.value)}`);
        }
    }

    public updateData(customId: number): void {
        this.params.context.componentParent.onNodeClick(customId);
    }

    public getGui(): HTMLElement {
        return this.eGui;
    }

    public refresh(params: ICellRendererParams): boolean {
        return true;
    }

    public destroy(): void {
        if (this.eGui) {
            this.eGui.removeEventListener('click', this.eventListener);
        }
    }

    public formatValue(value: string) {
        return value.replace(/^.*?=/,'');
    }

    public folderIcon(icon: string): string {
        return `<span class="fa-solid fa-folder${icon} fa-fw" style="color: #6acae9;"></span>`
    }

    public materializedFolderIcon(icon: string): string {
        return `<span class="fa-stack fa-fw" style="color: #6acae9; width: 1.28571em;">
                    <i class="fa-solid fa-folder${icon} fa-stack-1x"></i>
                    <i class="fa-solid fa-star fa-stack-1x fa-inverse" style="font-size: 5px; bottom:-3px; margin-left: 15%;"></i>
                </span>`
    }

    public spinnerIcon(): string {
        return `<i *ngIf="isLoading" class="fas fa-spinner fa-pulse mr-2 loading-animate"></i>`
    }
}